import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cancelTokens: {},
    card_info:{}
  },
  getters: {
  },
  mutations: {
    addToken(state, { key, cancel }) {
      state.cancelTokens[key] = cancel
    },
    delToken({ cancelTokens }, key) {
      if (cancelTokens[key]) {
        delete cancelTokens[key]
      }
    },
    cancel({ cancelTokens }, key) {
      if (cancelTokens[key]) {
        cancelTokens[key]('请求取消')
        delete cancelTokens[key]
      }
    },
    cancelAll({ cancelTokens }) {
      Object.keys(cancelTokens).forEach(key => {
        cancelTokens[key]('请求取消')
      })
      cancelTokens = {}
    },
    setInfo(state,{key,val}){
      state.card_info[key] = val
    }
  },
  actions: {
  },
  modules: {
  }
})
