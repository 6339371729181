import instance from "@/request";

/** 登录 */
export const indexCardLogin = (params) => {
    return instance({
        url: "/api/index/card/login",
        method: "POST",
        data: params
    });
};

/**查询手机号下的卡号*/
export const indexCardRetCardData = (params) => {
    return instance({
        url: "/api/index/card/ret_card_data",
        method: "POST",
        data: params
    });
};

/** 检查手机号 */
export const indexCardVerifyMobile = (params) => {
    return instance({
        url: "/api/index/card/verify_mobile",
        method: "POST",
        data: params
    });
};

/** tip */
export const indexIndexTipInfo = (params) => {
    return instance({
        url: "/api/index/index/custom_tips_info",
        method: "POST",
        data: params
    });
};

/** 发送验证码 */
export const indexSmsSend = (params) => {
    return instance({
        url: "/api/index/sms/send",
        method: "POST",
        data: params
    });
};

/** 首页轮播图 */
export const indexIndexBanner = (params) => {
    return instance({
        url: "/api/index/index/banner",
        method: "POST",
        data: params,
    });
};

/** 提货须知 */
export const indexIndexGuide = (params) => {
    return instance({
        url: "/api/index/index/guide",
        method: "POST",
        data: params,
    });
};

/** 系统公告 */
export const indexIndexNotice = (params) => {
    return instance({
        url: "/api/index/index/notice",
        method: "POST",
        data: params,
    });
};

/** 底部导航 */
export const cardIndexSiteMenuMainMenu = (params) => {
    return instance({
        url: "/api/index/site_menu/main_menu",
        method: "POST",
        data: params,
    });
};
/** 卡券详情 */
export const memberCardCardSelection = (params) => {
    return instance({
        url: "/api/member/card/card_selection",
        method: "POST",
        data: params
    });
};
/** 购物车添加 */
export const memberCartAdd = (params) => {
    return instance({
        url: "/api/member/cart/add",
        method: "POST",
        data: params
    });
};

/** 购物车查询 */
export const memberCartQuery = (params) => {
    return instance({
        url: "/api/member/cart/query",
        method: "POST",
        data: params
    });
};
/** 创建订单前的验证 */
export const memberGoodsOrderCheckIsCanChange = (params) => {
    return instance({
        url: "/api/member/goods_order/check_is_can_change",
        method: "POST",
        data: params
    });
};

/** 商品详情 */
export const memberCardCardDetails = (params) => {
    return instance({
        url: "/api/member/card/card_details",
        method: "POST",
        data: params
    });
};

/** 商品规格详情 */
export const member_product_config_attribute_product_attr_list = (params) => {
    return instance({
        url: "/api/member/product_config_attribute/product_attr_list",
        method: "POST",
        data: params
    });
};

//购物车选中状态
export const memberCartGoodsChecked = (params) => {
    return instance({
        url: "/api/member/cart/goods_checked",
        method: "POST",
        data: params
    });
};

/** 购物车数量change */
export const memberCartcartGoodsCountUpt = (params) => {
    return instance({
        url: "/api/member/cart/cart_goods_count_upt",
        method: "POST",
        data: params
    });
};
/** 购物车移除 */
export const memberCartDelCarProduct = (params) => {
    return instance({
        url: "/api/member/cart/del_car_product",
        method: "POST",
        data: params
    });
};
/** 期望发货日期返回 */
export const memberGoodsOrderExpectedDeliveryDate = (params) => {
    return instance({
        url: "/api/member/goods_order/expected_delivery_date",
        method: "POST",
        data: params
    });
};

export const index_config_config_list = (params) => {
    return instance({
        url: "/api/index/config/config_list",
        method: "POST",
        data: params
    });
};

/** 地区列表 */
export const memberCityQuery = (params) => {
    return instance({
        url: "/api/member/city/query",
        method: "POST",
        data: params
    });
};
export const memberCityQuery2 = (params) => {
    return instance({
        url: "/api/member/my/query",
        method: "POST",
        data: params
    });
};
/** 下单 */
export const memberGoodsOrderCreate = (params) => {
    return instance({
        url: "/api/member/goods_order/create",
        method: "POST",
        data: params
    });
};

/** 查询手机号下的卡号 */
export const memberGoodsOrderCreateSuccessMsg = (params) => {
    return instance({
        url: "/api/member/goods_order/create_success_msg",
        method: "POST",
        data: params
    });
};

/** 卡券添加 */
export const memberGoodsOrderTradePayment = (params) => {
    return instance({
        url: "/api/index/pay/trade_payment",
        method: "POST",
        data: params,
    });
};

/** 登录请求 */
export const indexOauthRetOauthConfig = (params) => {
    return instance({
        url: "/api/index/oauth/ret_oauth_config",
        method: "POST",
        data: params,
    });
};

/** 请求参数 */
export const indexPayMain = (params) => {
    return instance({
        url: "/api/index/pay/main",
        method: "POST",
        data: params,
    });
};

/** 请求参数 */
export const payWeixinOfficialApiJsapi = (params) => {
    return instance({
        url: "/pay/weixin/official/api_jsapi.php",
        method: "POST",
        data: params,
    });
};

/** 地区详情 */
export const memberCityEditMsg = (params) => {
    return instance({
        url: "/api/member/city/edit_query",
        method: "POST",
        data: params
    });
};

/** 地区删除 */
export const memberCityDelete = (params) => {
    return instance({
        url: "/api/member/city/delete",
        method: "POST",
        data: params
    });
};

/** 地区编辑 */
export const memberCityUpdate = (params) => {
    return instance({
        url: "/api/member/city/update",
        method: "POST",
        data: params
    });
};

/** 地区添加 */
export const memberCityAdd = (params) => {
    return instance({
        url: "/api/member/city/add",
        method: "POST",
        data: params
    });
};

/** 地区 */

export const memberGoodsOrderReturnArea = (params) => {
    return instance({
        url: "/api/member/city/return_area",
        method: "POST",
        data: params
    });
};

/** 底部导航 */
export const indexCardCardVerify = (params) => {
    return instance({
        url: "/api/index/card/card_verify",
        method: "POST",
        data: params,
    });
};

/** 商品详情 */
export const indexCardCardDetails = (params) => {
    return instance({
        url: "/api/index/card/card_details",
        method: "POST",
        data: params
    });
};

/** 转增列表 */
export const memberLargessBlessingLst = (params) => {
    return instance({
        url: "/api/member/largess/blessing_lst",
        method: "POST",
        data: params,
    });
};

/** 创建转增信息 */
export const memberLargessLargessCreate = (params) => {
    return instance({
        url: "/api/member/largess/largess_create",
        method: "POST",
        data: params,
    });
};

/** 获取 oss token */
export const memberOssOssConfig = (params) => {
    return instance({
        url: "/api/member/oss_config",
        method: "POST",
        data: params,
    });
};
/** 转增创建 */
export const memberLargessCreateLargess = (params) => {
    return instance({
        url: "/api/member/largess/create_largess",
        method: "POST",
        data: params,
    });
};

/** 祝福语 */
export const memberLargessLargessComplete = (params) => {
    return instance({
        url: "/api/member/largess/largess_complete",
        method: "POST",
        data: params,
    });
};

/** 发送短信 */
export const memberLargessLargessSendNotice = (params) => {
    return instance({
        url: "/api/member/largess/send_notice",
        method: "POST",
        data: params,
    });
};

/** 详情 */
export const memberLargessLargessVerify = (params) => {
    return instance({
        url: "/api/index/largess/receive",
        method: "POST",
        data: params,
    });
};

/** 领取礼物 */
export const memberLargessLargessReceive = (params) => {
    return instance({
        url: "/api/index/largess/verify",
        method: "POST",
        data: params,
    });
};

/** 订单列表 */
export const indexLogisticsDeliveryQueryResult = (params) => {
    return instance({
        url: "/api/index/logistics/delivery_query_result",
        method: "POST",
        data: params,
    });
};

export const index_card_voucher_info = (params) => {
    return instance({
        url: "/api/index/card/voucher_info",
        method: "POST",
        data: params,
    });
};

/** 是否有这个人 */
export const indexCardIsExistsUser = (params) => {
    return instance({
        url: "/api/index/my/is_exists_user",
        method: "POST",
        data: params,
    });
};

export const indexCardRegister = (params) => {
    return instance({
        url: "/api/index/my/register",
        method: "POST",
        data: params,
    });
};
/** 订单详情 */
export const indexLogisticsDeliveryQuery = (params) => {
    return instance({
        url: "/api/index/logistics/delivery_query",
        method: "POST",
        data: params,
    });
};

/** 物流跟踪 */
export const indexLogisticsTrackLog = (params) => {
    return instance({
        url: "/api/index/logistics/track_log",
        method: "POST",
        data: params,
    });
};

/** 我的卡券 */
export const memberMyMyHolder = (params) => {
    return instance({
        url: "/api/member/my/my_holder",
        method: "POST",
        data: params,
    });
};

/** 我的卡券 */
export const memberMyCheckIsAdd = (params) => {
    return instance({
        url: "/api/member/my/check_is_add",
        method: "POST",
        data: params,
    });
};
/** 卡券添加 */
export const memberMyMyCardAdd = (params) => {
    return instance({
        url: "/api/member/my/my_card_add",
        method: "POST",
        data: params,
    });
};

/** 右侧商品 */
export const indexGiftBookExchangeProduct = (params) => {
    return instance({
        url: "/api/index/gift_book/exchange_product",
        method: "POST",
        data: params,
    });
};

/** 商品详情 */
export const indexGiftBookProductDetails = (params) => {
    return instance({
        url: "/api/index/gift_book/product_details",
        method: "POST",
        data: params,
    });
};

/** 左侧导航 */
export const indexGiftBookBookList = (params) => {
    return instance({
        url: "/api/index/gift_book/book_list",
        method: "POST",
        data: params,
    });
};
//左侧导航
export const apiIndexIndexSeoInfo = (params) => {
    return instance({
        url: "/api/index/index/seo_info",
        method: "POST",
        data: params,
    });
};
/* 二维码登录 */
export const apiIndexCardCodeLogin = (params) => {
    return instance({
        url: "/api/index/card/code_login",
        method: "POST",
        data: params,
    });
};
/* 自定义样式 */
export const api_index_index_custom_css_style = (params) => {
    return instance({
        url: "/api/index/index/custom_css_style",
        method: "POST",
        data: params
    });
};
export const api_index_wx_share_get_weixin_share_info = (params) => {
    return instance({
        url: "/api/index/wx_share/get_weixin_share_info",
        method: "POST",
        data: params,
    })
}

export const api_member_integral_conversion_integral_conversion_platform_list = (params) => {
    return instance({
        url: "/api/member/integral_conversion/integral_conversion_platform_list",
        method: "POST",
        data: params,
    })
}

export const api_member_integral_conversion_integral_conversion_login = (params) => {
    return instance({
        url: "/api/member/integral_conversion/integral_conversion_login",
        method: "POST",
        data: params,
    })
}

export const api_member_integral_conversion_integral_conversion = (params) => {
    return instance({
        url: "/api/member/integral_conversion/integral_conversion",
        method: "POST",
        data: params,
    })
}

/** 获取祝福语和封面图 */
export const api_member_largess_create_largess_need_info = (params) => {
    return instance({
        url: "/api/member/largess/create_largess_need_info",
        method: "POST",
        data: params,
    })
}
/** 点击下一步 */
export const api_member_largess_new_create_largess = (params) => {
    return instance({
        url: "/api/member/largess/new_create_largess",
        method: "POST",
        data: params,
    })
}
/** 获取短信预览内容 */
export const api_member_largess_get_send_sms_content = (params) => {
    return instance({
        url: "/api/member/largess/get_send_sms_content",
        method: "POST",
        data: params,
    })
}
/** 发送短信 */
export const api_member_largess_new_send_notice = (params) => {
    return instance({
        url: "/api/member/largess/new_send_notice",
        method: "POST",
        data: params,
    })
}
/** 转增领取页面数据 */
export const api_index_largess_receive_info = (params) => {
    return instance({
        url: "/api/index/largess/receive_info",
        method: "POST",
        data: params,
    })
}

// const api_index_wx_share_get_weixin_share_info = (params) => {
//     return instance({
//         url:"/api/index/wx_share/get_weixin_share_info",
//         method:"POST",
//         data:params,
//     })
// }