import axios from "axios";
import {Toast} from 'vant';
import router from "@/router";
import store from "@/store";
import {clear_card_info, get_card_token} from "@/utils/card_info";
import {get_user_token} from "@/utils/user_info";
import {handle_error_2_tips, handle_error_3_tips} from "@/request/handle";

/** 初始化axios请求 */
const instance = axios.create({
    //所有请求的前缀部分
    baseURL: "",
    //请求超时时间
    timeout: 60000,
});
const controller = new AbortController();

/** 请求拦截 */
instance.interceptors.request.use(async function (config) {
    if (process.env.NODE_ENV === 'development') {
        // config.baseURL = "http://192.168.2.109:8686";
        config.baseURL = "https://demo.v8th.cn";
    } else {
        // config.baseURL = "https://demo.v8th.cn";
        if (extJson) {
            config.baseURL = `${extJson.apiDomain}`;
        } else {
            Toast("接口域名获取错误，请联系客服！！！");
        }
    }
    config.cancelToken = new axios.CancelToken(function (cancel) {
        store.commit('addToken', {key: config.url + '' + config.data, cancel});
    });
    //获取token
    let token = get_card_token();
    let token2 = get_user_token();
    config.headers = Object.assign(config.headers, {
        "CARD-TOKEN": token,
        "USER-TOKEN": token2,
    });
    config.data = Object.assign({signal: controller.signal}, config.data);
    return config;
});
/** 响应拦截 */
instance.interceptors.response.use(function (response) {
    store.commit('delToken', response.config.url + response.config.data);
    if (response.status === 200) {
        if (response.data.code === 200 || response.data.code === 201) {
            return response.data;
        } else if (response.data.code === -1) {
            try {
                Toast.clear();
                clear_card_info();
                let path = router.app._route.path;
                if (path === "/") {
                } else {
                    router.replace({path: `/`});
                }
                Toast({
                    message: (response.data && (response.data.message || response.data.msg)) || "访问异常",
                    duration: 3000
                });
                return Promise.reject(response.data);
            } catch (err) {
                Toast.clear();
                return Promise.reject(err);
            }
        } else {
            if (response.data.type === 0) {
                handle_error_2_tips(response.data);
            } else if (response.data.type === 1) {
                handle_error_2_tips(response.data);
            } else {
                handle_error_3_tips(response.data);
            }
            return Promise.reject(response.data);
        }
    }
});
export default instance;