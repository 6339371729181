import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: () => import("@/views/main/MainView.vue"),
        children: [
            {
                path: "/",
                name: "index",
                component: () => import("@/views/index/IndexView.vue"),
                meta: {title: "首页", keepAlive: false},
            },
            {
                path: "/product_list",
                name: "product_list",
                component: () => import("@/views/product_list/ProductList.vue"),
                meta: {title: "选品", keepAlive: true},
            },
            {
                path: "/product_details",
                name: "product_details",
                component: () => import("@/views/product_details/ProductDetails.vue"),
                meta: {title: "商品详情", keepAlive: false},
            },
            {
                path: "/shopping_cart",
                name: "shopping_cart",
                component: () => import("@/views/shopping_cart/ShoppingCart.vue"),
                meta: {title: "购物车", keepAlive: false}
            },
            {
                path: "/confirm_order",
                name: "confirm_order",
                component: () => import("@/views/confirm_order/ConfirmOrder.vue"),
                meta: {title: "创建订单", keepAlive: false}
            },
            {
                path: "/order_pay",
                name: "order_pay",
                component: () => import("@/views/order_pay/OrderPay.vue"),
                meta: {title: "订单支付", keepAlive: false}
            },
            {
                path: "/order_success",
                name: "order_success",
                component: () => import("@/views/order_success/OrderSuccess.vue"),
                meta: {title: "支付成功", keepAlive: false}
            },
            {
                path: "/address_list",
                name: "address_list",
                component: () => import("@/views/address_list/AddressList.vue"),
                meta: {title: "地址列表", keepAlive: false}
            },
            {
                path: "/address_edit",
                name: "address_edit",
                component: () => import("@/views/address_edit/AddressEdit.vue"),
                meta: {title: "地址添加", keepAlive: false}
            },
            {
                path: "/test_and_verify",
                name: "test_and_verify",
                component: () => import("@/views/test_and_verify/TestAndVerify.vue"),
                meta: {title: "真伪验证", keepAlive: false}
            },
            {
                path: "/verification_results",
                name: "verification_results",
                component: () => import("@/views/verification_results/VerificationResults.vue"),
                meta: {title: "真伪结果", keepAlive: false}
            },
            {
                path: "/other_product_detail",
                name: "other_product_detail",
                component: () => import("@/views/other_product_detail/OtherProductDetail.vue"),
                meta: {title: "商品详情", keepAlive: false}
            },
            {
                path: "/create_info",
                name: "create_info",
                component: () => import("@/views/create_info/CreateInfo.vue"),
                meta: {title: "赠送", keepAlive: false}
            },
            {
                path: "/create_largess",
                name: "create_largess",
                component: () => import("@/views/create_largess/CreateLargess.vue"),
                meta: {title: "赠送", keepAlive: false}
            },
            {
                path: "/give_result",
                name: "give_result",
                component: () => import("@/views/give_result/GiveResult.vue"),
                meta: {title: "赠送", keepAlive: false}
            },
            {
                path: "/give_receive",
                name: "give_receive",
                component: () => import("@/views/give_receive/GiveReceive.vue"),
                meta: {title: "领取", keepAlive: false}
            },
            {
                path: "/shipment_query",
                name: "shipment_query",
                component: () => import("@/views/shipment_query/ShipmentQuery.vue"),
                meta: {title: "发货查询", keepAlive: false}
            },
            {
                path: "/my_order",
                name: "my_order",
                component: () => import("@/views/my_order/MyOrder.vue"),
                meta: {title: "我的订单", keepAlive: false}
            },
            {
                path: "/take_goods",
                name: "take_goods",
                component: () => import("@/views/take_goods/TakeGoods.vue"),
                meta: {title: "登录", keepAlive: false}
            },
            {
                path: "/system_announcement",
                name: "system_announcement",
                component: () => import("@/views/system_announcement/SystemAnnouncement.vue"),
                meta: {title: "系统公告", keepAlive: false}
            },
            {
                path: "/my",
                name: "my",
                component: () => import("@/views/my/MyView.vue"),
                meta: {title: "我的", keepAlive: false}
            },
            {
                path: "/login",
                name: "login",
                component: () => import("@/views/login/LoginView.vue"),
                meta: {title: "登录", keepAlive: false}
            },
            {
                path: "/bind_phone",
                name: "bind_phone",
                component: () => import("@/views/bind_phone/BindPhone.vue"),
                meta: {title: "绑定手机", keepAlive: false}
            },
            {
                path: "/bind_card",
                name: "bind_card",
                component: () => import("@/views/bind_card/BindCard.vue"),
                meta: {title: "绑定卡券", keepAlive: false}
            },
            {
                path: "/order_detail",
                name: "order_detail",
                component: () => import("@/views/order_detail/OrderDetail.vue"),
                meta: {title: '订单详情', keepAlive: false}
            },
            {
                path: "/logistics_details",
                name: "logistics_details",
                component: () => import("@/views/logistics_details/LogisticsDetails.vue"),
                meta: {title: "物流详情", keepAlive: false}
            },
            {
                path: "/about_us",
                name: "about_us",
                component: () => import("@/views/about_us/AboutUs.vue"),
                meta: {title: '关于我们', keepAlive: false}
            },
            {
                path: "/my_card",
                name: "my_card",
                component: () => import("@/views/my_card/MyCard.vue"),
                meta: {title: "我的卡包", keepAlive: false}
            },
            {
                path: "/card_add",
                name: "card_add",
                component: () => import("@/views/card_add/CardAdd.vue"),
                meta: {title: "卡券添加", keepAlive: false}
            },
            {
                path: "/tip",
                name: "tip",
                component: () => import("@/views/tip/TipView.vue"),
                meta: {title: "提示", keepAlive: false}
            },
            {
                path: "/gift_book_details",
                name: "gift_book_details",
                component: () => import("@/views/gift_book_details/GiftBookDetails.vue"),
                meta: {title: "套餐详情", keepAlive: false}
            },
            {
                path: "/gift_product_details",
                name: "gift_product_details",
                component: () => import("@/views/gift_product_details/GiftProductDetails.vue"),
                meta: {title: "套餐商品详情", keepAlive: false}
            },
            {
                path: "/gift_book_package",
                name: "gift_book_package",
                component: () => import("@/views/gift_book_package/GiftBookPackage.vue"),
                meta: {title: "礼册套餐", keepAlive: false}
            },
            {
                path: "/product_detail",
                name: "product_detail",
                component: () => import("@/views/product_detail/ProductDetail.vue"),
                meta: {title: "商品详情", keepAlive: false}
            },
            {
                path: "/r/:number",
                name: "r",
                component: () => import("@/views/r/Rview.vue"),
                meta: {title: "地址跳转", keepAlive: false}
            },
            {
                path: "/login/ercode",
                name: "login_ercode",
                component: () => import("@/views/login_ercode/LoginErcode.vue"),
                meta: {title: "二维码登录", keepAlive: false}
            },
            {
                path: "/chongyang",
                name: "chongyang",
                component: () => import("@/views/chongyang/ChongYangView.vue"),
                meta: {title: "请您领取重阳节福利", keepAlive: false}
            },
            {
                path: '/test',
                name: 'test',
                component: () => import("@/views/test/TestView.vue"),
                meta: {title: "测试", keepAlive: false}
            },
            {
                path: '/integral_conversion/platform_list',
                name: 'integral_conversion_platform_list',
                component: () => import("@/views/integral_conversion/platform_list/PlatformList.vue"),
                meta: {title: "积分转换列表", keepAlive: false}
            },
            {
                path: '/integral_conversion/login',
                name: 'integral_conversion_login',
                component: () => import("@/views/integral_conversion/login/Login.vue"),
                meta: {title: "积分转换平台登录", keepAlive: false}
            },
            {
                path: '/integral_conversion/success',
                name: 'integral_conversion_success',
                component: () => import("@/views/integral_conversion/success/Success.vue"),
                meta: {title: "积分转换成功", keepAlive: false}
            }

        ]
    },
    {
        path: "/404",
        name: "404",
        component: () => import("@/views/404/404.vue"),
        meta: {title: "404", keepAlive: false}
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/404'
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
router.beforeEach(async (to, from, next) => {
//路由跳转前执行所有取消函数
    store.commit('cancelAll');
    next();
});
export default router;
